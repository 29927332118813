// import APP_CONFIG from "@/apps/core/modules/config.js";

// function canUseWebp() {
//     console.log("can use webp");
//     const elem = document.createElement("canvas");
//     if (elem.getContext && elem.getContext('2d')) {
//         // was able or not to get WebP representation
//         const canUseWebp = elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
//         return canUseWebp;
//     }
//     return false;
// }

// function checkWebpFeature(feature, callback) {
//     var kTestImages = {
//         lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
//         lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
//         alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
//         animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
//     };
//     var img = new Image();
//     img.onload = function () {
//         var result = (img.width > 0) && (img.height > 0);
//         callback(feature, result);
//     };
//     img.onerror = function () {
//         callback(feature, false);
//     };
//     img.src = "data:image/webp;base64," + kTestImages[feature];
// }

// let webpSupport = false;
// checkWebpFeature('lossy', function (feature, isSupported) {
//     webpSupport = isSupported;
// });

function webpPath(url) {
    return url;
    // if (webpSupport) return url;
    // const imgUrl = url.replace(APP_CONFIG.baseS3Url, '');
    // return `${APP_CONFIG.toolsViewWebpUrl}${imgUrl}`;
}

export { webpPath };
