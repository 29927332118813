import APP_CONFIG from "@/apps/core/modules/config.js";
import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "anggota";
const modelPath = "/anggota/";

class Anggota extends ModelBase {
  constructor() {
    let model = {
      id: null,
      nama: "",
      user: "",
      foto: `${APP_CONFIG.baseS3Url}/ppdh/img/profil.png`,
      pspd: null,
      preceptor: null,
    }
    super(modelName, model, [], [], modelPath);
  }

  getLoadData(data) {
    let anggota = super.getLoadData(data);
    if (!anggota.foto) {
      anggota.foto = `${APP_CONFIG.baseS3Url}/ppdh/img/profil.png`;
    }
    return anggota;
  }
}

export default Anggota;